import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';

const AboutMePage: FunctionComponent<any> = ({ data: { contentfulAboutUsPage } }) => {
  return (
    <CmsContext.Provider value={contentfulAboutUsPage}>
      <Seo
        title={contentfulAboutUsPage.pageMeta.metatitle}
        description={contentfulAboutUsPage.pageMeta.metadescription}
      />
      <App />
    </CmsContext.Provider>
  );
};

export default AboutMePage;

export const query = graphql`
  query AboutMePage {
    contentfulAboutUsPage {
      pageMeta {
        metatitle
        metadescription
      }
      video {
        videoID
        videoRatio
      }
      videoTitle
      FirstTextTitle
      firstTextDescription
      firstTextImage {
        fluid(quality: 100, maxWidth: 800, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      teamTopic
      team {
        ... on ContentfulTeamMember {
          name
          role
          image {
            fluid(quality: 100, maxWidth: 208, background: "rgb:000000") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      founderTopic
      founder {
        ... on ContentfulTeamMember {
          name
          role
          image {
            fluid(quality: 100, maxWidth: 208, background: "rgb:000000") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      storyTitle
      storyContent {
        storyContent
      }
    }
  }
`;
